$text_color_default: #676767;
$text_color_accent: #333;
$text_color_secondary: #ffb200;

$background_color_default: #f3f3f5;

$font_family_default: 'Ubuntu', sans-serif;

$header_height: 110px;

// extra stall smartphones
$screen-xs-min: 321px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1140px;

// Large tablets and desktops
$screen-xl-min: 1900px;

// Extra small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Media with heigh
@mixin hsm {
  @media (min-width: #{$screen-sm-min}) and (min-height: 600px) {
    @content;
  }
}

@mixin hmd {
  @media (min-width: #{$screen-md-min}) and (min-height: 400px) {
    @content;
  }
}

@mixin hlg {
  @media (min-width: #{$screen-lg-min}) and (min-height: 800px) {
    @content;
  }
}

@mixin hxl {
  @media (min-width: #{$screen-xl-min}) and (min-height: 1288px) {
    @content;
  }
}

$z-index-landing: 1;
$z-index-modal-background: 1;
$z-index-modal-portal: 500;
$z-index-modal-content: 510;
