@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap);
body{margin:0;padding:0;background:#f3f3f5;color:#676767;font-family:"Ubuntu",sans-serif}html,body,#__next{height:100%;z-index:-1;position:relative;scroll-behavior:smooth;width:100%}ul{margin:0;padding:0}ul{padding:0}li{list-style:none}a{color:#676767;text-decoration:none}img{-webkit-user-select:none;-ms-user-select:none;user-select:none;width:100%;height:auto}:focus{outline:none}button{color:#676767;font-family:"Ubuntu",sans-serif}input{color:#676767}h2{font-family:Luckiest Guy, serif;font-style:normal;font-weight:normal;font-size:48px;line-height:48px}h3{font-style:normal;font-weight:normal;font-size:26px;line-height:32px}h4{font-size:26px;font-style:normal;font-weight:400;line-height:26px;letter-spacing:0;margin:0}p{font-size:18px;font-style:normal;font-weight:400;line-height:32px;letter-spacing:0;color:#e9e9e9;margin:0}

.thumbNailView img{height:60px;width:60px;border-radius:50%}

.HeaderInfo_headerInfo__2-Skw{display:flex;align-items:center;justify-content:flex-start;min-width:190px;margin:10px;font-weight:bolder}.HeaderInfo_headerInfo__2-Skw p{color:#676767;display:block;font-size:12px;line-height:18px;margin:0;font-weight:bold}.HeaderInfo_icon__3vgPU{margin-right:10px}.HeaderInfo_title__1zPZd{color:#333}

.layout_container__7Bz5N{display:flex;align-items:center;flex-direction:column;width:100%;height:100%}.layout_headerWrapper__2bebD{width:100%;display:flex;justify-content:center;align-items:center;background-color:#f3f3f5}@media screen and (max-width: 870px){.layout_headerWrapper__2bebD{height:100%}}.layout_header__3EqFU{height:100%;display:flex;align-items:center;justify-content:space-between;margin:0 auto;z-index:5;width:100%;flex-direction:column}@media (min-width: 1140px){.layout_header__3EqFU{width:1140px}}@media (min-width: 768px){.layout_header__3EqFU{flex-direction:row}}.layout_logo__3uXom{width:140px;height:80px;margin:20px}.layout_logo__3uXom::before{content:'';width:140px;height:80px;background:url(/static/media/logo.470ed628.png) center;background-size:cover;position:absolute}.layout_logo__3uXom img{width:100%}.layout_headerMenu__3hfp5{display:flex;justify-content:center;align-items:center;flex-wrap:wrap;margin:20px}@media (min-width: 768px){.layout_headerMenu__3hfp5{justify-content:space-between}}.layout_headerInfo__1NLQx{display:flex;margin-top:20px;margin-bottom:20px}.layout_footerWrapper__1zbbf{height:276px;width:100%;display:flex;justify-content:center;position:relative}.layout_footerWrapper__1zbbf::after{content:'';width:100%;height:100%;position:absolute;background:url(/static/media/footer.91096bd3.png) center;background-size:100%;background-blend-mode:multiply;z-index:-1}.layout_footer__2_d9v{width:70%;margin-top:70px;display:flex;flex-direction:column;justify-content:center}@media screen and (max-width: 700px){.layout_footer__2_d9v{width:90%;margin:0}}.layout_footer__2_d9v .layout_text__2uhN1{display:block;position:relative;padding-bottom:12px;color:#fff;font-weight:600}.layout_footer__2_d9v .layout_text__2uhN1:after{content:"";position:absolute;left:0;top:100%;width:60px;height:3px;background-color:#ffbc13}.layout_footer__2_d9v .layout_company__Nl13w{color:#ffffff}

